import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { Container } from 'react-bootstrap'
import Title from '../components/title'

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif'
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320
}

const paragraphStyles = {
  marginBottom: 48
}

// markup
const Success = () => {
  return (
    <Layout>
      <Title picture title='Newsletters' />
      <Container>
        <main style={pageStyles}>
          <title>Success</title>
          <h1 style={headingStyles}>Thank You!</h1>
          <p style={paragraphStyles}>
            We will be in touch with you soon.
            <span role='img' aria-label='happy emoji'>
              😊
            </span>{' '}
            <br />
            <br />
            <Link to='/'>Go home</Link>.
          </p>
        </main>
      </Container>
    </Layout>
  )
}

export default Success
